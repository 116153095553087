@import "assets/styles/_colors";
@import "assets/styles/_fonts";

.PickemStandingsPage {
    .max50 {
        width: 100%;
        max-width: 50%;
        margin: auto;
    }
    .col > label {
        color: #cdd6da;
    }

    &-weeks {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.1em;
        padding: 0;
    }

    &-currentWeek {
        border: 1px solid $ORANGE_300;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5),
        0px 0px 10px rgba(241, 86, 44, 0.2);
        background: transparent
    }

    &-notCurrentWeek {
        background: transparent
    }   

    &-selectedWeek {
        background-color: $ORANGE_500;
        color: $WHITE_500;
    }

    &-weekView {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    &-weekButton {
        color: #cdd6da;
        padding: 0.25em;
        width: 1.25em;
        height: 1.25em;
        
        &:hover {
            border: 1px solid $ORANGE_300;
            box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5),
                0px 0px 10px rgba(241, 86, 44, 0.2);
            }
    }
}